@font-face {
    font-family: 'Liberteen Thin';
    src: url('Liberteen Thin.otf') format('truetype');
}

@font-face {
    font-family: 'Liberteen Light';
    src: url('Liberteen Light.otf') format('truetype');
}

@font-face {
    font-family: 'Liberteen Regular';
    src: url('Liberteen Regular.otf') format('truetype');
}

@font-face {
    font-family: 'Liberteen Medium';
    src: url('Liberteen Medium.otf') format('truetype');
}

@font-face {
    font-family: 'Liberteen Bold';
    src: url('Liberteen Bold.otf') format('truetype');
}

@font-face {
    font-family: 'Liberteen Black';
    src: url('Liberteen Black.otf') format('truetype');
}






